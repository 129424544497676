@import '../variables';

// lazy loading block
.lazy-loading {
	background: linear-gradient(
		135deg,
		rgba(245, 245, 245, 0.5) 30%,
		rgba(245, 245, 245, 0.5) 40%,
		rgba(#11394d, 0.1) 50%,
		rgba(245, 245, 245, 0.6) 60%,
		rgba(245, 245, 245, 0.6) 80%
	);
	background-size: 1200% 1200%;
	animation: lazyLoading ($TRANSITION_NORMAL * 5) ease infinite reverse;
}

.loading-skeleton {
	-webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.6) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
	mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.6) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
	-webkit-mask-size: 200%;
	mask-size: 200%;
	animation: shine 1000ms ease infinite;
	color: transparent;
	background-color: $LIGHT_GRAY;
}

// placeholders
.placeholder {
	&--text {
		line-height: 1;
		display: inline;
		box-decoration-break: clone;

		span {
			color: transparent;
			line-height: 2;
		}

		&.lazy-loading {
			background-size: 600% 600%;
		}
	}

	&--block {
		display: flex;
		width: 100px;
		height: 50px;
	}
}

// lazy-loading animation
@keyframes lazyLoading {
	0% {
		background-position: 0 92%;
	}
	100% {
		background-position: 100% 9%;
	}
}

@keyframes shine {
	from {
		-webkit-mask-position: 150%;
	}
	to {
		-webkit-mask-position: -50%;
	}
}

@keyframes shine {
	from {
		-webkit-mask-position: 150%;
	}
	to {
		-webkit-mask-position: -50%;
	}
}
