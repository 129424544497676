@import '../variables';

// generic input, select fields
input,
select {
	height: 56px;
	background-color: $WHITE;
	outline: none;
	border: 1px solid $PRIMARY;
	border-radius: 10px;
	padding: 0 1rem;
	font-size: 14px;
	color: $PRIMARY;
	transition: 0.4s;
	max-width: 500px;
	width: 100%;

	&::placeholder {
		color: $GRAY;
	}

	&:focus {
		border-width: 2px;
	}

	&.is-invalid {
		border-color: $danger;

		& ~ .invalid-feedback,
		& ~ .label-indicator {
			&:not(.is-valid) {
				color: $danger;
			}
		}
	}

	&.is-valid {
		border-color: $success;

		& ~ .label-indicator {
			color: $success;
		}
	}
}

// input field
input {
	&[type='checkbox'] {
		margin-right: 5px;
		height: 16px;
		width: unset;
	}
}

// select input
select {
	-webkit-appearance: none;
	background: #fff url(https://cdn2.iconfinder.com/data/icons/essential-web-4/50/angle-down-arrow-direction-bottom-16.png) no-repeat right;
	background-position-x: 95%;
}

// label
label {
	font-weight: $FW_BOLD;
	display: block;

	& span {
		font-weight: $FW_REGULAR;
	}

	&:not(.label-indicator) > span {
		font-size: 10px;
	}

	&.label-indicator {
		color: $text-muted;

		&:not(:first-of-type) {
			margin-top: 10px;
		}

		&.is-valid {
			color: $success;
		}

		&.is-invalid {
		}
	}
}

// password input container
.controller--password {
	position: relative;

	& > span {
		position: absolute;
		right: 10px;
		top: 0;
		height: 100%;
		width: 32px;
		display: flex;
		place-items: center;
		font-size: 1.25rem;
		cursor: pointer;
	}
}

// range input
.controller--range {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	//padding-bottom: 2.5rem;
	font-weight: $FW_BOLD;

	&__label {
		width: 100%;
		font-size: 14px;
		margin-bottom: 3rem;
	}

	&__min,
	&__max {
		font-size: 14px;
		margin: 0 1.5rem;
		text-align: center;
		font-weight: normal;
		color: $body-color;
		white-space: break-spaces;
		max-width: 25%;
	}

	&__max {
		margin-right: 0.5rem;
		text-align: right;
	}

	&__min {
		margin-left: 0.5rem;
		text-align: left;
	}

	.rc-slider {
		flex-grow: 1;
		width: unset !important;

		&-rail {
			background: #b9b9b9;
			height: 10px;
		}

		&-track {
			background: $PRIMARY;
			height: 10px;
			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
		}

		&-dot {
			border-radius: 50%;
			background: $WHITE;
			width: 12px;
			height: 12px;
			bottom: -6px;

			&-active {
				border: none;
			}
		}

		&-mark-text {
			margin-top: 0.5rem;
			& .selected {
				font-size: 1rem;
				color: $PRIMARY;
			}
		}

		&-handle {
			width: 32px;
			height: 32px;
			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25);
			border: none;
			margin-top: -12px;
			position: relative;
			color: $PRIMARY;

			.controller--range__tooltip {
				position: absolute;
				top: calc(-100%);
				min-width: 32px;
				white-space: nowrap;
				height: 32px;
				font-size: 16px;
				color: inherit;
				text-align: center;
				font-weight: $FW_BOLD;
				left: 50%;
				transform: translateX(-50%);
				&:not(.visible) {
					display: none;
				}
			}
		}

		// for defined values using marked steps
		&.rc-slider-with-marks {
			.rc-slider-track {
				opacity: 0;
			}

			.rc-slider-dot {
				box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25);
				width: 24px;
				height: 24px;
				bottom: -12px;
				margin-left: -12px;
			}

			.rc-slider-handle {
				border: 1px solid $PRIMARY;
			}
		}

		&.chip-slider {
			.rc-slider-track {
				opacity: 1;
			}

			.rc-slider-dot {
				display: none;
			}

			.rc-slider-mark {
				display: none;
			}

			.rc-slider-handle {
				display: flex;
				width: 32px;
				height: 32px;
				box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25);
				border: none;
				margin-top: -12px;
				position: relative;
			}
		}
	}
}

// control box
.controller--box {
	display: flex;
	flex-wrap: wrap;
	border-radius: 10px;
	border: 1px solid $PRIMARY;

	// el: label
	&__label {
		display: flex;
		width: 100%;
		padding: 1rem;
		margin-bottom: 0;
		cursor: pointer;
		align-items: center;

		// el: label indicator
		&__indicator {
			height: 24px;
			width: 24px;
			flex-shrink: 0;
			background: $WHITE;
			border: 1px solid $PRIMARY;
			border-radius: 50%;
			margin-right: 1rem;
			position: relative;

			& > * {
				display: none;
			}
		}

		// el: label text
		&__text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-grow: 1;
			font-size: 14px;

			&__item {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
	}

	// el: controller content
	&__content {
		width: 100%;
		display: none;
		overflow: hidden;
		flex-direction: column;
		color: $PRIMARY;
		&:not(:empty) {
			padding: 0 1rem 1rem 1rem;
		}
	}

	// mod: checkbox, slider
	&--checkbox,
	&--slider {
		.controller--box__label {
			.controller--box__label__indicator {
				border-radius: 5px;
				display: grid;
				place-items: center;
				color: $PRIMARY;
			}
		}
	}

	// mod: radio
	&--radio {
		.controller--box__label {
			.controller--box__label__indicator::after {
				box-sizing: unset;
				content: '';
				position: absolute;
				display: none;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				height: 12px;
				width: 12px;
				border-radius: 50%;
				background: #fff;
			}
		}
	}

	// mod: selected
	&--selected {
		border-width: 3px;

		.controller--box__label {
			.controller--box__label__text,
			.controller--box__label__text span {
				font-weight: $FW_BOLD;
			}
			.controller--box__label__text,
			.controller--box__label__text small * {
				font-weight: $FW_REGULAR;
			}
		}

		.controller--box__content {
			display: flex;
		}

		// mod: selected radio
		&.controller--box--radio {
			.controller--box__label__indicator {
				background: $PRIMARY;
			}
			.controller--box__label__indicator::after {
				display: block;
			}
		}

		// mod: selected checkbox / slider
		&.controller--box--checkbox,
		&.controller--box--slider {
			.controller--box__label {
				.controller--box__label__indicator {
					color: $WHITE;
					background: $SUCCESS;
					border: none;

					& > * {
						display: unset;
					}
				}
			}
		}
	}
}

// rc-slider override
.rc-slider-tooltip-inner {
	min-width: 32px;
	height: 32px;
	font-size: 16px;
	color: $PRIMARY;
	font-weight: $FW_BOLD;
	background-color: transparent;
	box-shadow: none;
}
