@import '../variables';
@import '../layout/grid';

.card {
	border-radius: 10px !important;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	max-width: $MAX_CARD_WIDTH;
	width: 100%;
	padding: 1rem;
	border: none !important;
	background-color: $LIGHT_GRAY;
	color: #1f2427;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: background-color $TRANSITION_NORMAL ease-in 0ms, background $TRANSITION_NORMAL ease-in 0ms, box-shadow $TRANSITION_NORMAL;
	transition-delay: 0ms;

	&:not(:last-of-type) {
		margin-bottom: 1rem;
	}

	&:hover {
		box-shadow: none !important;
	}

	svg {
		margin-right: 10px;
	}

	&.lazy-loading {
		box-shadow: none;
		transition-delay: unset;

		.card__container {
			visibility: hidden;
			opacity: 0;
			transition-delay: unset;
		}
	}

	// el: card container
	&__container {
		visibility: visible;
		opacity: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		transition: opacity $TRANSITION_SLOW ease-out;
		transition-delay: inherit;
	}

	// el: content container
	&__content {
		display: flex;
		justify-content: space-between;
	}

	// el: info
	&__info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 75%;
	}

	// el: name (title)
	&__name {
		// font-size: 18px;
		font-size: clamp(17px, 2.5vw, 19px);
		line-height: 27px;
		font-weight: 700;
		margin-bottom: 0;
	}

	// el: subtitle
	&__subtitle {
		margin-bottom: 0.5rem;
	}

	// el: sidebar
	&__sidebar {
		display: grid;
		place-items: start right;
		width: 25%;

		img {
			max-width: 100%;
			max-height: 100px;
		}

		@media screen and (max-width: 250px) {
			text-align: center;
			width: 100%;
		}
	}

	// el: footer container
	&__footer {
		display: flex;
		overflow-y: scroll;
		width: 100%;
		@extend .hide-scrollbar;

		a:not(.card__link) {
			border: 1px solid $BLACK;
			border-radius: 10px;
			padding: 5px 15px;
			font-weight: 400;
			color: white;
			margin-top: 20px;
			text-decoration: underline;
			white-space: nowrap;

			&:not(:last-of-type) {
				margin-right: 10px;
			}

			&:hover,
			&.card__link--active {
				background-color: $BLACK;
				color: $WHITE;
			}

			&.card__link--active {
				text-decoration: none;
				font-weight: 700;
			}

			@media screen and (max-width: 330px) {
				max-width: 140px;
				display: flex;
				justify-content: center;
			}
		}
	}

	// el: link
	&__link {
		text-decoration: underline;
		width: 100%;
		display: flex;
		align-items: center;
		padding-top: 1rem;
		font-size: 14px;
		cursor: pointer;
	}

	// mod: event card
	&--event {
		span,
		a:not(.card__link) {
			// font-size: 14px;
			font-size: clamp(13px, 2vw, 15px);
			font-weight: 400;
			color: lightgray;
			margin-bottom: 0.25rem;
			display: flex;
			align-items: baseline;
		}
	}

	// mod: ticket card
	&--ticket {
		span {
			font-size: 14px;
		}
	}

	// mod: mini card / square
	&--mini {
		display: inline-block;
		position: relative;
		max-width: calc(#{($MAX_CARD_WIDTH / 2)} - 0.75rem);
		width: calc(50% - 0.75rem);

		// ensure card to be square
		&::before {
			content: '';
			display: block;
			margin-top: 100%;
		}

		.card__container {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			flex-wrap: wrap;
			padding: inherit;

			&:hover {
				cursor: pointer;
			}
		}

		.card__content {
			height: 100%;
		}

		.card__info {
			width: 100%;
			height: 100%;
		}

		.card__name {
			margin-top: auto;

			@media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
				font-size: 12px;
			}
		}

		span:not(.card__name) {
			font-size: 14px;

			@media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
				font-size: 10px;
			}
		}

		.card__logo {
			height: 50%;
			display: flex;
			justify-content: flex-start;
			width: 100%;

			& > img {
				max-width: 100%;
				max-height: 100%;
				object-fit: scale-down;
			}

			@media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
				height: 25%;
			}
		}
	}

	// mod: news card
	&--news {
		$thumbnail-height: 120px;
		box-shadow: none;
		position: relative;
		padding-top: 3rem;
		padding-left: calc(1rem + 10px);
		padding-right: calc(1rem + 10px);
		background-color: $LIGHT_GRAY !important;
		margin-top: calc(#{$thumbnail-height} - 1rem);
		&.grid__item {
			margin: calc(#{$grid-spacing-y} + #{$thumbnail-height} - 1rem) $grid-spacing-x $grid-spacing-y;
		}

		.card__thumbnail {
			background-color: $PRIMARY;
			height: $thumbnail-height;
			width: calc(100% - 2rem);
			border-radius: 10px;
			position: absolute;
			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
			top: calc(-#{$thumbnail-height} + 2rem);
			left: 50%;
			transform: translateX(-50%);
			overflow: hidden;
			display: grid;
			place-items: center;

			& > img {
				width: 100%;
				height: 100%;
				object-fit: scale-down;
			}
		}

		.card__name {
			color: $PRIMARY;
			margin-bottom: 0.5rem;
		}

		.card__subtitle {
			margin-bottom: 0;
			color: $GRAY;
			font-size: 12px;
		}

		.card__link {
			color: $SUCCESS;
		}
	}

	// mod: order card
	&--order {
		background-color: $LIGHT_GRAY !important;
		box-shadow: none;

		.card__info {
			width: 100%;
		}

		.card__subtitle {
			margin-bottom: 0;
			font-size: 12px;
		}
	}

	// TODO - mod: chip card

	// mod: band card
	&--band {
		background-size: cover;
		min-height: 200px;
		padding: 0;
		box-shadow: unset;
		overflow: hidden;

		.card__container {
			padding: 1rem;
			opacity: 1;
			margin-top: auto;
			height: unset;
			background-color: rgba($white, 0.5);
			border-radius: 0 0 10px 10px; // safari WTF

			.card__info {
				width: 100%;
			}

			@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
				-webkit-backdrop-filter: blur(10px);
				backdrop-filter: blur(10px);
			}
		}
	}

	&--band-sm {
		.card__info {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.card__logo {
			border-radius: 50rem;
			overflow: hidden;
			width: 60%;
			height: 60%;
		}

		.card__name {
			margin-top: 0.75rem;
		}
	}
}
