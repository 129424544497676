@import '../variables';

.profile {
	// el: header
	&__header {
		display: flex;
		flex-direction: column;

		h2 {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			& > span {
				display: flex;
				align-items: center;

				&:not(:last-of-type) {
					flex-wrap: wrap;
					margin-right: 0.5rem;
				}
			}
		}
	}

	// el: profile image
	&__image {
		$profileImgSize: 96px;
		display: flex;
		flex-direction: column;
		align-items: center;

		&-container {
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			width: $profileImgSize;
			height: $profileImgSize;
			border-radius: 50rem;
			background-color: $DEEP_BLUE;

			@media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
				width: $profileImgSize * 0.75;
				height: $profileImgSize * 0.75;
			}

			& > img {
				flex-shrink: 1;
				min-width: 100%;
				min-height: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-name {
			margin-top: 0.75rem;
			text-align: center;
			line-height: inherit;
		}

		&--gallery .profile__image-container {
			cursor: pointer;
			$borderWidth: 4px;
			position: relative;
			width: $profileImgSize + 2 * $borderWidth;
			height: $profileImgSize + 2 * $borderWidth;
			padding: $borderWidth;

			@media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
				width: ($profileImgSize * 0.75) + 2 * $borderWidth;
				height: ($profileImgSize * 0.75) + 2 * $borderWidth;
			}

			& > img {
				border-radius: 50%;
				z-index: 1;
			}

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background: linear-gradient(60deg, #{$SUCCESS}, #{lighten($SUCCESS, 50%)});
				border-radius: 50rem;
				z-index: 0;
				animation: animatedGradient 3s ease alternate infinite;
				background-size: 300% 300%;
			}

			&:hover::after {
				background: $SUCCESS;
				animation: unset;
			}

			@keyframes animatedGradient {
				0% {
					background-position: 0 50%;
				}
				50% {
					background-position: 100% 50%;
				}
				100% {
					background-position: 0 50%;
				}
			}
		}
	}

	// el: card
	&__card {
		@extend .pass__card;

		&-header {
			@extend .profile__header;
			margin-bottom: 1rem;
		}

		&-socials {
			display: flex;

			& > a {
				&:not(:last-of-type) {
					margin-right: 0.5rem;
				}

				color: $black;
				width: 24px;
				height: 24px;
			}
		}
	}

	// el: gallery
	&__gallery {
		.modal-dialog {
			height: 100%;
			max-width: unset;
			margin: 0;

			.modal-content {
				display: flex;
				height: 100%;
				background: transparent;
				border: unset;
			}

			.profile__gallery-container {
				height: 100%;
				display: flex;
				flex-direction: column;
				padding: 1rem;

				// progress bars
				.profile__gallery-progress {
					display: flex;

					.progress {
						flex-grow: 1;
						height: 0.25rem;
						background-color: rgba($white, 0.1);
						margin: 0 0.25rem;

						&:last-of-type {
							margin-right: 0;
						}

						&:first-of-type {
							margin-left: 0;
						}

						&-bar {
							background-color: rgba($white, 0.75);
							transition: unset;
						}

						&.progressing {
							.progress-bar {
								transition: width $TRANSITION_FAST;
							}
						}
					}
				}

				// gallery exit button
				.profile__gallery-exit {
					background-color: $white;
					color: $black;
					border-radius: 50rem;
					width: 2rem;
					height: 2rem;
					display: grid;
					place-items: center;
					cursor: pointer;
					transition: transform $TRANSITION_NORMAL;

					&:hover {
						transform: scale(1.05);
					}
				}

				// gallery navigation arrows
				.profile__gallery-arrow {
					cursor: pointer;
					opacity: 0.25;
					transition: opacity $TRANSITION_NORMAL;
					flex-grow: 1;
					height: 100%;
					display: grid;

					&:hover {
						opacity: 0.8;
					}

					&:first-of-type {
						margin-right: 0.5rem;
						place-items: center left;
					}

					&:last-of-type {
						margin-left: 0.5rem;
						place-items: center right;
					}
				}

				// main image container
				.profile__gallery-image {
					display: grid;
					place-items: center;
					height: 100%;
					position: relative;
					min-width: 50%;

					// image
					& > img {
						max-height: 100%;
						max-width: 100%;
						object-fit: scale-down;
					}
				}
			}
		}
	}

	// mod: performer profile
	&--performer {
		$profileImgSize: 96px;

		.profile__header {
			min-height: $profileImgSize / 2; // ensures the profile image wont overflow
			max-width: $COMPACT_LAYOUT_WIDTH;
			padding-bottom: 0.25rem !important;

			h2 {
				margin-top: auto;
				margin-left: calc(#{$profileImgSize} + 1rem); // + profile-image margin
			}
		}

		.profile__image {
			align-items: unset;

			&--gallery .profile__image-container {
				border-width: 6px;
			}

			&-container {
				width: $profileImgSize;
				height: $profileImgSize;
				margin-right: 1rem;
				margin-top: calc(#{-$profileImgSize / 2} - 1rem); // - container y padding
				border-radius: 50rem;
			}
		}
	}

	// mod: band profile
	&--band {
		$profileImgSize: 200px;

		.profile__card {
			margin-top: calc(#{$profileImgSize / 2} + 1rem);
		}

		.profile__card .profile__image-container {
			$profileImgSizeSm: 64px;
			width: $profileImgSizeSm;
			height: $profileImgSizeSm;
			border-radius: 50rem;
		}

		.profile__card-header {
			.profile__image .profile__image-container {
				width: 100%;
				height: $profileImgSize;
				margin-top: calc(#{-$profileImgSize / 2} - 1rem); // - container y padding
				border-radius: 10px;
				margin-bottom: 1rem;
			}

			.profile__image--gallery .profile__image-container {
				cursor: pointer;
				border: none !important;
				position: relative;
				width: 100% !important;
				height: $profileImgSize !important;
				padding: unset !important;

				& > img {
					border-radius: unset !important;
				}

				&::before {
					content: '' !important;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					box-shadow: inset 0px -3rem 3rem -1.5rem $black;
					transition: background-color $TRANSITION_NORMAL;
					z-index: 2;
				}

				&::after {
					content: attr(data-expand-text) !important;
					position: absolute;
					width: 100% !important;
					height: unset !important;
					text-align: center;
					bottom: 0.5rem !important;
					font-size: 0.75rem;
					color: $white;
					opacity: 0.75;
					transition: opacity $TRANSITION_NORMAL;
					z-index: 2 !important;
					top: unset;
					left: unset;
					background: unset;
				}

				&:hover {
					&::after {
						opacity: 1;
					}

					&::before {
						background-color: rgba($black, 0.4);
					}
				}
			}
		}
	}
}
