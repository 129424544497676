/* document root variables */
:root {
	--body-bg: #f6f6f6;
	--body-color: #000000;
}

/* override bootstrap variables */
@import './scss/overrides';

/* import react-toastify styles */
@import '~react-toastify/scss/main';

/* import rc-slider styles */
@import '~rc-slider/assets/index.css';

/* import animate.css library */
@import '~animate.css';

/* import core styles */
@import './scss/main';
