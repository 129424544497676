/* bootstrap variable overrides here */
// ...
/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

// multiple modal fix
@for $i from 1 through 6 {
	$zIndexBackdrop: #{1000 + (5 * $i)};
	$zIndexContent: #{1000 + (5 * $i) + 2};
	.modal-backdrop.show:nth-of-type(#{$i}) {
		z-index: $zIndexBackdrop;
	}
	div[role='dialog'][aria-modal='true']:nth-of-type(#{$i}) {
		z-index: $zIndexContent;
	}
}

// button
.btn {
	font-weight: 400 !important;
}

// modal content radius
.modal-content {
	border-radius: 10px !important;
}

// modal title header
.modal-header,
.modal-title {
	@extend .h3;
}

// form groups
.form-group {
	max-width: 500px;
	&:last-of-type {
		margin-bottom: 0 !important;
	}

	.controller--box,
	.controller--slider,
	.controller--radio {
		&:not(:last-of-type) {
			margin-bottom: 1rem;
		}
	}
}

// add container padding
$CONTAINER_PADDING: 1rem;
.container-fluid {
	padding-bottom: $CONTAINER_PADDING;
	padding-top: $CONTAINER_PADDING;
}
