@import '../variables';

// adds box-shadow
@mixin _box-shadow($color: $BOX_SHADOW_COLOR, $y: $BOX_SHADOW_Y, $x: $BOX_SHADOW_X, $blur: $BOX_SHADOW_BLUR, $inset: false) {
	@if $inset {
		-webkit-box-shadow: inset $x $y $blur $color;
		-moz-box-shadow: inset $x $y $blur $color;
		box-shadow: inset $x $y $blur $color;
	} @else {
		-webkit-box-shadow: $x $y $blur $color;
		-moz-box-shadow: $x $y $blur $color;
		box-shadow: $x $y $blur $color;
	}
}
