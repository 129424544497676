@import '../variables';

// loader wrapper
.loader-wrapper {
	display: grid;
	place-items: center;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 999;
	position: absolute;
	background: $WHITE;
	opacity: 0.975;

	// mod: block display
	&--block {
		background: transparent;
		position: unset;
		z-index: unset;
	}

	// mod: full window
	&--full {
		width: 100vw;
		height: 100vh;
		position: fixed;
	}

	// el: content wrapper
	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	// el: text
	&__text {
		margin: 0;
		margin-top: -10px;
		font-weight: bold;
	}

	// el: spinner
	&__loader-inner {
		padding-top: 15px;
		position: relative;
		transform: rotate(50deg) scale(0.5);
		width: auto;
		display: inline-block;
	}

	// el: spinner hexagon
	&__hexagon {
		position: relative;
		width: 50px;
		height: 28.87px;
		background-color: transparent;
		margin: 14.43px 0;
		border-left: solid 7px var(--body-color);
		border-right: solid 7px var(--body-color);
		animation: load1 6s 0s infinite ease;

		&::before,
		&::after {
			content: '';
			position: absolute;
			z-index: 1;
			width: 35.36px;
			height: 35.36px;
			-webkit-transform: scaleY(0.5774) rotate(-45deg);
			-ms-transform: scaleY(0.5774) rotate(-45deg);
			transform: scaleY(0.5774) rotate(-45deg);
			background-color: inherit;
			left: 0.3223px;
		}

		&::before {
			top: -17.6777px;
			border-top: solid 9.8995px var(--body-color);
			border-right: solid 9.8995px var(--body-color);
		}

		&::after {
			bottom: -17.6777px;
			border-bottom: solid 9.8995px var(--body-color);
			border-left: solid 9.8995px var(--body-color);
		}
	}

	// el: spinner arrows
	&__arrows {
		position: absolute;
		right: 0;
		top: 0;
		transform: translateX(-50%) rotate(0deg);
		animation: load2 1s 0s infinite ease;

		&__arrow {
			position: relative;
			width: 30px;
			height: 10px;
			background-color: transparent;
			border-left: solid 7px transparent;
			border-right: solid 7px transparent;
			margin: 0;

			&:first-child {
				margin-bottom: 5px;
				margin-top: -5px;
				animation: load3 1s 0s infinite ease;
			}

			&::before,
			&::after {
				content: '';
				position: absolute;
				z-index: 1;
				width: 24.75px;
				height: 24.75px;
				-webkit-transform: scaleY(0.5774) rotate(-45deg);
				-ms-transform: scaleY(0.5774) rotate(-45deg);
				transform: scaleY(0.5774) rotate(-45deg);
				background-color: inherit;
				left: -1.8744px;
			}

			&::before {
				top: -12.3744px;
				border-top: solid 9.8995px var(--body-color);
				border-right: solid 9.8995px var(--body-color);
			}

			&::after {
				bottom: -12.3744px;
				border-bottom: solid 9.8995px transparent;
				border-left: solid 9.8995px transparent;
			}
		}
	}
}

@keyframes load1 {
	#{(0) * 16.666666%} {
		transform: rotate(#{0 * 60deg});
	}
	#{(0 + 0.3) * 16.666666%} {
		transform: rotate(#{0 * 60deg});
	}

	@for $i from 1 through 5 {
		#{($i - 0.3) * 16.666666%} {
			transform: rotate(#{$i * 60deg});
		}
		#{($i + 0.3) * 16.666666%} {
			transform: rotate(#{$i * 60deg});
		}
	}

	#{(6 - 0.3) * 16.666666%} {
		transform: rotate(#{6 * 60deg});
	}
	#{(6) * 16.666666%} {
		transform: rotate(#{6 * 60deg});
	}
}

@keyframes load2 {
	0% {
		transform: translateX(-50%) rotate(0deg) translateY(0px);
	}
	30% {
		transform: translateX(-50%) rotate(0deg) translateY(0px);
	}
	40% {
		transform: translateX(-50%) rotate(0deg) translateY(-10px);
	}
	50% {
		transform: translateX(-50%) rotate(0deg) translateY(-10px);
	}
	60% {
		transform: translateX(-50%) rotate(0deg) translateY(-10px);
	}
	70% {
		transform: translateX(-50%) rotate(0deg) translateY(0px);
	}
	100% {
		transform: translateX(-50%) rotate(0deg) translateY(0px);
	}
}

@keyframes load3 {
	0% {
		transform: translateY(0px);
	}
	30% {
		transform: translateY(0px);
	}
	40% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(-5px);
	}
	60% {
		transform: translateY(0px);
	}
	70% {
		transform: translateY(0px);
	}
	100% {
		transform: translateY(0px);
	}
}
