@import '../mixins/common';
@import '../variables';

#program-container {
	min-width: calc(100% + 30px);
	margin-left: -15px;

	// override bootstrap tab pane styling
	.tab-content .tab-pane {
		overflow: scroll;
		border: 1px solid $black;
		border-radius: 10px;
		position: relative;
		@extend .hide-scrollbar;
	}

	// override bootstrap nav tabs styling
	.nav.nav-tabs {
		border-bottom: unset;
		overflow-y: scroll;
		width: 100%;
		flex-wrap: nowrap;
		@extend .hide-scrollbar;

		.nav-item.nav-link {
			border-radius: 10px 10px 0 0;
			background-color: $LIGHT_GRAY;
			margin: 0 0.25rem;
			color: $black;
			&:first-of-type {
				margin-left: 10px;
			}

			border: 1px solid transparent;
			border-bottom: unset;
			padding: 0.25rem 0.5rem;
			font-size: 0.8rem;
			&.active {
				border-color: $black;
				background-color: transparent;
				font-weight: $FW_BOLD;
			}

			&:not(.active):hover {
				border-color: rgba($black, 0.5);
			}
		}
	}

	// program scroll container
	.program__scroll-container {
		@extend .hide-scrollbar;
	}

	// program timeline
	.program-timeline {
		$header-height: 40px;
		$row-gap: 10px;
		height: auto; // TODO: fixed height with overflow?
		display: grid;
		row-gap: $row-gap;
		position: relative;
		// width: EventProgramSection.tsx;
		// grid-template-columns: EventProgramSection.tsx;
		// grid-template-rows: EventProgramSection.tsx;

		// el: toolbar
		&__toolbar {
			position: absolute;
			bottom: $row-gap;
			left: $row-gap;
			display: flex;
			z-index: 6;

			&-item {
				cursor: pointer;
				width: 35px;
				height: 35px;
				display: grid;
				place-items: center;
				opacity: 0.8;
				transition: opacity $TRANSITION_NORMAL;
				border: 1px solid $LIGHT_GRAY;
				background-color: $white;

				&:hover {
					opacity: 1;
				}

				&:not(last-of-type) {
					border-right: 1px solid $LIGHT_GRAY;
				}

				&:first-of-type {
					border-radius: 5px 0 0 5px;
				}

				&:last-of-type {
					border-radius: 0 5px 5px 0;
				}
			}
		}

		// el: timeline header
		&__header {
			// grid-column: EventProgramSection.tsx;
			display: flex;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			align-items: flex-start;
			// height: EventProgramSection.tsx;

			& > div {
				display: grid;
				place-items: center;
				font-size: 0.8rem;
				top: 0;
				height: $header-height - $row-gap;
				position: sticky;
				background-color: $LIGHT_GRAY;
				border-bottom: 1px solid rgba($black, 0.1);
				// width: EventProgramSection.tsx;

				&:not(:last-of-type)::after {
					content: '';
					position: absolute;
					width: 1px;
					height: calc(100% - #{$row-gap});
					right: 0;
					top: $row-gap;
					background-color: rgba($black, 0.1);
				}
			}
		}

		// el: background
		&__bg {
			position: absolute;
			display: flex;
			width: 100%;
			// height: EventProgramSection.tsx;

			& > div:not(:last-of-type) {
				height: calc(100% - #{2 * $row-gap});
				margin: $row-gap 0;
				border-right: 1px solid rgba($black, 0.1);
			}
		}

		// el: stage cell
		&__stage {
			z-index: 4;
			cursor: pointer;
			// grid-column: EventProgramSection.tsx;

			&:first-of-type {
				margin-top: $header-height;
			}

			& > span {
				height: 100%;
				font-weight: $FW_BOLD;
				position: sticky;
				top: 0;
				left: 10px;
				display: block;

				padding: 0.25rem 0.5rem;
				text-align: center;
				@include border-radius($BORDER_RADIUS);
				@include _box-shadow();
				// width: EventProgramSection.tsx;
				// background-color: EventProgramSection.tsx;
				// color: EventProgramSection.tsx;
			}

			&--default {
				cursor: unset;
				& > span {
					background-color: $LIGHT_GRAY;
				}
			}
		}

		// el: nested entry grid
		&__entry-grid {
			display: grid;
			row-gap: 10px;
			grid-auto-flow: column;
			// grid-column: EventProgramSection.tsx;
			// grid-template-columns: EventProgramSection.tsx;

			&:last-of-type {
				padding-bottom: $row-gap;
			}
		}

		// el: entry cell
		&__entry {
			display: grid;
			place-items: center;
			border-radius: 10px;
			z-index: 1;
			background-color: $white;
			padding: 0.5rem;
			@include border-radius($BORDER_RADIUS);
			border: 1px solid black;
			cursor: pointer;
			height: 80px;
			// grid-column: EventProgramSection.tsx;

			& > .program-timeline__entry-content {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				width: 100%;
				height: 100%;
				overflow: hidden;

				& > .program-timeline__entry-text {
					flex: 1 0 auto;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: flex-start;
					flex-direction: column;
					max-width: 100%;

					span,
					time {
						display: block;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 100%;
					}

					span {
						font-weight: $FW_BOLD;
					}

					time {
						font-size: 0.8rem;
					}
				}

				& > .program-timeline__entry-icon {
					margin-left: 0.5rem;
				}
			}
		}
	}
}
