@import '../mixins/common';
@import '../variables';

.tile {
	position: relative;
	display: flex;
	overflow: hidden;
	flex-direction: column;
	justify-content: space-between;
	min-height: 144px;
	padding: $CONTAINER_PADDING;
	transition: box-shadow $TRANSITION_NORMAL;
	@include border-radius($BORDER_RADIUS);
	@include _box-shadow();
	color: #1f2427;
	border: none;
	background-color: $LIGHT_GRAY;

	&:hover {
		box-shadow: none;
	}

	&__body {
		display: flex;
	}

	&__title,
	.profile__image-name {
		font-size: 18px;
		font-weight: 700;
		line-height: 27px;
	}

	&__subtitle,
	&__text,
	&__link {
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
	}

	&__text {
		margin-top: 5px;
	}

	&__footer {
		display: flex;
		overflow-y: scroll;
		width: 100%;
		margin-top: 20px;
		@extend .hide-scrollbar;
	}

	&__link {
		text-decoration: underline;
		color: inherit;
		cursor: pointer;

		&:hover {
			color: inherit;
			opacity: 0.85;
		}
	}

	// mod: performer tile
	&--performer {
		.profile__image-name {
			@extend .tile__title;
		}
	}

	// mod: band card
	&--band {
		color: $white;
		background-color: $black;
		background-size: cover;

		// mod: large band card
		&--lg {
			min-height: 200px;
			padding: 0;

			.tile__body {
				height: auto;
				margin-top: auto;
				padding: $CONTAINER_PADDING;
				color: $black;
				background-color: rgba($white, 0.6);

				// ellipsis the overflow text
				.tile__text,
				.tile__title {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
					-webkit-backdrop-filter: blur(10px);
					backdrop-filter: blur(10px);
				}
			}
		}
	}

	&--voucher {
		min-height: unset;
		background: none;
		border: 1px solid black;
		box-shadow: none;
	}

	// mod: compact order
	&--order-compact {
		width: 100% !important;
	}

	// mod: order detail
	&--order-detail {
		flex-direction: row;
		box-shadow: none;
		align-items: center;
		width: 100% !important;
		min-height: 60px;

		.tile__body {
			width: 50%;
		}

		.tile__footer {
			margin-top: 0;
			margin-left: 2rem;
			width: 50%;
		}

		@media screen and (max-width: map-get($grid-breakpoints, 'xl')) {
			.tile__body {
				width: calc(100% / 1.5); // 66.6%
			}
			.tile__footer {
				width: calc(100% / 3); // 33.3%
			}
		}

		@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
			flex-wrap: wrap;
			min-height: 100px;

			.tile__body,
			.tile__footer {
				width: 100%;
			}

			.tile__footer {
				margin-left: 0;
				margin-top: 20px;
			}
		}
	}

	// mod: chip
	&--chip {
		min-height: unset;
	}

	// mod: mini tile
	&--mini {
		height: 100%;
		padding: 0;

		// ensure card to be square
		&::before {
			display: block;
			margin-top: 100%;
			content: '';
		}

		.tile__body {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			padding: $CONTAINER_PADDING;
		}

		.tile__title {
			@extend .tile__subtitle;
			font-weight: 700;
		}

		.tile__text {
			margin-top: 0;
		}

		// mod: mini performer tile
		&.tile--performer {
			.profile__image {
				max-width: 100%;
			}

			.profile__image-name {
				@extend .tile__title;
				margin-top: 0.5rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}

			.profile__image-container {
				width: 112px;
				height: 112px;
				@media screen and (max-width: map-get($grid-breakpoints, 'md')) {
					width: 96px;
					height: 96px;
				}
			}

			&:hover .profile__image-container {
				@include box-shadow();
			}
		}

		// mod: mini band tile
		&.tile--band {
			.tile__body {
				z-index: 1;

				&::before {
					position: absolute;
					z-index: -1;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					content: '';
					background-color: rgba($black, 0.45);
				}
			}

			.tile__title {
				margin-top: auto;
			}
		}
	}

	// mod: error tile
	&--error {
		cursor: pointer;
		color: $DANGER;
		border: 1px solid $DANGER;
		background: #ffefef;
		@include _box-shadow(rgba($DANGER, 0.1));

		.tile__icon {
			width: 48px;
			height: 48px;
		}
	}

	// mod: interactive (clickable) tile
	&--interactive {
		cursor: pointer;
	}

	&--warning {
		border: 1px solid $warning;
		background-color: #fff6db;
		@include _box-shadow(rgba($warning, 0.1));
	}

	&--danger {
		border: 1px solid $danger;
		background-color: #ffd9dd;
		@include _box-shadow(rgba($danger, 0.1));
	}
}
