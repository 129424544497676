// transitions
.transition {
	// mod: page transition
	&--page {
		&-appear {
			opacity: 0;
			&-active {
				opacity: 1;
				transition: opacity 500ms;
			}
		}
		&-enter {
			opacity: 0;
			&-active {
				opacity: 1;
				transition: opacity 500ms;
			}
		}
		&-exit {
			opacity: 1;
			display: none;
			&-active {
				opacity: 0;
				transition: opacity 500ms;
			}
		}
	}

	// mod: smooth fade-in-out
	&--smooth {
		&-appear {
			opacity: 0;
			&-active {
				opacity: 1;
				transition: opacity 300ms;
			}
		}
		&-enter {
			opacity: 0;
			&-active {
				opacity: 1;
				transition: opacity 300ms;
			}
		}
		&-exit {
			opacity: 1;
			&-active {
				opacity: 0;
				transition: opacity 300ms;
			}
		}
	}
}
