@import '../variables';

// #pass__footer
.pass__footer {
	// mod: internal footer
	&--internal {
		width: 100%;
		position: relative;
		bottom: 0;
		padding: 2rem 0;
		color: var(--body-color);
		// background: var(--body-bg);
		background: var(--footer-bg);
		// margin-top: 20px;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: -10px;
			width: 100%;
			height: 10px;
			background-color: inherit;
			z-index: -1;
		}

		h5,
		p {
			font-size: 12px;
			font-weight: $FW_BOLD;
			line-height: 18px;
			text-align: center;
		}

		h5 {
			padding-bottom: 10px;
		}

		p {
			font-weight: $FW_REGULAR;
			text-align: center;
			padding: 40px 0;
		}

		a {
			font-size: 12px;
			color: var(--body-color);
			line-height: 18px;
			text-decoration: underline;
			display: block;
		}

		.footer__container {
			display: flex;
			margin: 0 auto;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;

			p {
				padding: 0;
			}

			@media screen and (max-width: 800px) {
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}
	}

	// mod: global footer
	&--global {
		width: 100%;
		position: relative;

		svg {
			width: 100%;
		}

		footer {
			margin: 0 auto;
			min-height: 30vh;
			background-color: $PRIMARY;

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: -10px;
				width: 100%;
				height: 10px;
				background-color: inherit;
				z-index: -1;
			}

			// el: footer container
			.footer__container {
				width: 100%;
				max-width: 1430px;
				height: 100%;
				margin: 0 auto;
				padding-bottom: 60px;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
			}

			// el: footer item
			.footer__item {
				min-width: 33%;

				h4 {
					font-weight: 700;
					color: #fff;
					font-size: 16px;
					line-height: 24px;
					margin-bottom: 20px;
					margin-top: 60px;
				}

				p {
					margin-top: 0;
					margin-bottom: 1rem;

					a {
						display: inline-block;
						margin-top: 20px;
						text-decoration: underline;
						color: #50c878;
						font-weight: 400;
					}

					&.footer__copyright {
						margin-top: 40px;
						color: $GRAY;
					}
				}

				//mod: item-overview
				&--overview {
					padding-left: 15px;
				}

				// mod: item-logo
				&--logo {
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 100%;
					}
				}

				// mod: item-social
				&--social {
					text-align: center;

					a {
						margin: 10px;
						width: 32px;
					}

					a:first-child {
						margin-left: 0;
					}
				}

				// mod: item-contact
				&--contact {
					text-align: right;
					padding-right: clamp(15px, 2vw, 30px);

					a {
						color: #50c878;
						font-weight: 700 !important;
						text-decoration: underline;
					}
				}
			}

			// media: x-large
			@media screen and (min-width: #{map-get($grid-breakpoints, 'xl')}) {
				.footer__item--logo {
					display: none;
				}
			}

			@media screen and (max-width: #{map-get($grid-breakpoints, 'xl')}) {
				.footer__container {
					width: 50%;
				}
			}

			// media: large
			@media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
				.footer__logo {
					display: none;
				}
			}

			@media screen and (max-width: #{map-get($grid-breakpoints, 'lg')}) {
				.footer__container {
					margin: 0 20px !important;
				}

				.footer__item--logo,
				.footer__item--social,
				.footer__item--contact {
					display: none !important;
				}

				.footer__logo {
					margin-top: 60px;
				}
			}
		}
	}
}

@media print {
	.pass__footer {
		display: none;
	}
}
