// variables
@import "./variables";

// miscellaneous
@import "./misc/loader";
@import "./misc/transitions";
@import "./misc/favorite";

// layout
@import "./layout/layout";
@import "./layout/print";

// components
@import "./components/button";
@import "./components/form";
@import "./components/gallery";
@import "./components/notification";

@import "./components/card";
@import "./components/tile";

@import "./components/receipt";
@import "./components/profile";
@import "./components/program";

// lazy-loading
@import "./misc/lazy-loading";

html {
	// -webkit property
	overflow-y: overlay;
}

body {
	background-color: var(--body-bg);
	//color: var(--body-color);
	color: $black;
	transition: background-color $TRANSITION_NORMAL ease, color $TRANSITION_NORMAL ease;
	font-family: "Poppins", sans-serif;
}

h2,
.h2 {
	// font-size: 24px;
	font-size: clamp(23px, 3vw, 25px);
	font-weight: $FW_BOLD;
}

h3,
.h3 {
	// font-size: 18px;
	font-size: clamp(17px, 2.5vw, 19px);
	font-weight: $FW_BOLD;
	color: $DEEP_BLUE;
}

// app root container
#root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

// link
.link {
	cursor: pointer;
	text-decoration: underline;
	color: $link-color;
}

// icons
[data-icon] {
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	object-fit: scale-down;

	&.big {
		width: 32px;
		height: 32px;
	}
}

// generic global state
.disabled {
	opacity: 0.35 !important;
	pointer-events: none !important;
	box-shadow: none !important;
	cursor: not-allowed;
}

// hide the scrollbar
.hide-scrollbar {
	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;
	scrollbar-width: none;
}

.btn-reset {
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;

	background: transparent;

	/* inherit font & color from ancestor */
	color: inherit;
	font: inherit;

	/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
	line-height: normal;

	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;

	/* Corrects inability to style clickable `input` types in iOS */
	-webkit-appearance: none;

}
