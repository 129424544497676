@import './overrides';

// type face
$FONT: 'Navigo', sans-serif;
$FW_BOLD: 700;
$FW_REGULAR: 400;

// base colors
$WHITE: $white;
$BLACK: $black;

// color palette
$PRIMARY: #312b6d;

$SUCCESS: #3df98d;
$INFO: #01eee0;
$DANGER: $danger;
$WARNING: $warning;

$LIGHT_GRAY: #f6f6f6;
$DEEP_BLUE: #1f2427;
$GRAY: #b9b9b9;

// transitions
$TRANSITION_FAST: 100ms;
$TRANSITION_NORMAL: 300ms;
$TRANSITION_SLOW: 750ms;

// layout
$MAX_CARD_WIDTH: 430px;
$COMPACT_LAYOUT_WIDTH: 460px;

// border
$BORDER_RADIUS: 10px;

// box shadow
$BOX_SHADOW_COLOR: rgba(0, 0, 0, 0.1);
$BOX_SHADOW_X: 0;
$BOX_SHADOW_Y: 0.5rem;
$BOX_SHADOW_BLUR: 1rem;
